.logout-pop-up {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
  width: 30rem;
  height: 12rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.5rem;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  border: 3px solid #eb0000;
  background-color: rgba(50, 50, 50, 0.58);
  transform: translateY(-50%) translateX(-50%);
}
.logout-pop-up h3 {
  margin-top: 0;
  font-size: 1.5rem;
  color: #ffffff;
}
.logout-pop-up div {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.logout-pop-up div button {
  width: 6rem;
  height: 2rem;
  color: #eb0000;
  border-radius: 0.25rem;
  font-family: "Goldman";
  background-color: transparent;
  border: 2px solid #eb0000;
  cursor: pointer;
}
.logout-pop-up div button:first-child {
  color: #494949;
  background-color: #eb0000;
}

.logout-pop-up-close {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 3;
}/*# sourceMappingURL=logoutpopup.css.map */