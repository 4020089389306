@import "./variables/variables.scss";
.logout-pop-up {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
  width: 30rem;
  height: 12rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.5rem;
  backdrop-filter: blur(15px);
  border: 3px solid $darkRedColor;
  background-color: $backgroundGreyColor;
  transform: translateY(-50%) translateX(-50%);
  h3 {
    margin-top: 0;
    font-size: 1.5rem;
    color: $whiteColor;
  }
  div {
    display: flex;
    column-gap: 1rem;
    button {
      width: 6rem;
      height: 2rem;
      color: $darkRedColor;
      border-radius: 0.25rem;
      font-family: "Goldman";
      background-color: transparent;
      border: 2px solid $darkRedColor;
      cursor: pointer;
    }
    button:first-child {
      color: $greyColor;
      background-color: $darkRedColor;
    }
  }
}
.logout-pop-up-close{
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 3;
}