@import url("https://fonts.googleapis.com/css2?family=Goldman:wght@400;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,400;1,500;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Goldman";
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --redColor: #e70000;
  --darkRedColor: #eb0000;
  --whiteColor: #ffffff;
  --greyColor: #494949;
  --darkGreyColor: #202020;
  --backgroundColor: #090909;
  --backgroundGreyColor: rgba(50, 50, 50, 0.58);
}
*::-webkit-scrollbar {
  width: 8px;
  background-color: var(--backgroundColor);
  opacity: 0;
}
*::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: var(--greyColor);
}