.product {
  padding: 1rem;
  height: 29rem;
  width: 25rem;
  border-radius: 1rem;
  border: 0.1rem solid var(--darkGreyColor);
  background-color: var(--backgroundColor);
  overflow: hidden;
  text-decoration: none;
}
.product a {
  all: unset;
  cursor: pointer;
}
.home-products-title {
  position: relative;
  margin-bottom: 2rem;
  color: var(--greyColor);
}
.home-products-title::after {
  content: "";
  position: absolute;
  top: 120%;
  left: 0;
  width: 100%;
  height: 0.15rem;
  border-radius: 1rem;
  background-color: var(--darkGreyColor);
}
.shoes-image {
  position: relative;
  width: 100%;
  height: 18.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shoes-image .content {
  background: url("../assists/shoes-preview.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.shoes-image::after {
  content: "";
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  height: 0.15rem;
  border-radius: 1rem;
  background-color: var(--darkGreyColor);
}
.name--model--cost {
  margin-top: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  color: var(--whiteColor);
}
.cost {
  font-size: 1.75rem;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}
.cost .off {
  text-decoration: line-through;
  color: var(--greyColor);
  font-size: 1.5rem;
}
.new-cost {
  color: var(--whiteColor);
  font-size: 1.75rem;
}
.stars--colors {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--greyColor);
}
.stars-container {
  position: relative;
  height: 1.25rem;
}
.stars {
  position: absolute;
  width: 6.5rem;
  height: 100%;
}
.stars-upper {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 0.09rem;
}
.star {
  width: 1.25rem;
}
.colors {
  font-size: 0.8rem;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}
.color {
  transition: all 500ms;
}
.color-selected {
  color: var(--redColor);
}
.product-button {
  margin-top: 1rem;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: 3px solid var(--redColor);
  background-color: transparent;
  color: var(--redColor);
  font-family: "Goldman";
  font-size: 1.1rem;
  transition: all 500ms;
  cursor: pointer;
}

.product-button.loading-button {
  border: 3px solid var(--darkGreyColor);
  color: var(--greyColor);
  animation: fading 2s ease-in infinite;
}
@keyframes fading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.product-button.added-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  color: var(--greyColor);
  border: 3px solid var(--darkGreyColor);
  cursor: default;
}
.added-to-cart-icon-contianer {
  position: relative;
  cursor: pointer;
}
.added-to-cart-icon-contianer::before {
  content: "";
  position: absolute;
  top: 0%;
  left: -30%;
  width: 0.15rem;
  height: 90%;
  border-radius: 1rem;
  background-color: var(--greyColor);
  cursor: default;
}
.product-button-enter {
  transform: scale(0.1);
  transition: all 300ms;
  position: relative;
  z-index: 4;
}
.product-button-enter-active {
  transform: scale(1);
  transition: all 300ms;
  position: relative;
  z-index: 2;
}
.product-button-exit {
  transform: scale(1);
  transition: all 300ms;
  position: relative;
  z-index: 1;
}
.product-button-exit-active {
  transition: all 300ms;
  transform: scale(0.1);
  position: relative;
  z-index: 1;
}
.poduct-button-container {
  height: 4rem;
  overflow: hidden;
}
@media screen and (max-width: 500px) {
  .product {
    width: 80vw;
    height: fit-content;
  }
  .shoes-image {
    height: 60vw;
  }
  .name--model--cost {
    font-size: 3vw;
  }
  .cost {
    font-size: 6vw;
    column-gap: 2vw;
  }
  .cost .off {
    font-size: 4vw;
  }
  .new-cost {
    font-size: 6vw;
  }
  .stars--colors {
    margin-top: 2vw;
  }
  .stars {
    width: 20vw;
  }
  .colors {
    font-size: 2.25vw;
    font-weight: 900;
    display: flex;
    column-gap: 2vw;
    align-items: center;
  }
  .product-button {
    height: 11vw;
    font-size: 3.5vw;
  }
  .poduct-button-container {
    height: 16vw;
  }
  .stars-container {
    position: relative;
    height: 4vw;
  }
  .stars-upper {
    top: 0;
    column-gap: 0.1vw;
  }
  .star {
    width: 4vw;
  }
}
