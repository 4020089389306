.cart-contianer {
  position: fixed;
  top: 5vh;
  right: 2.5%;
  z-index: 3;
  height: 85vh;
  width: 20rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: var(--backgroundGreyColor);
  backdrop-filter: blur(15px);
  border: 3px solid var(--redColor);
}
.cart-contianer header {
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--whiteColor);
}
.cart-close-button {
  color: var(--redColor);
  font-size: 2rem;
  cursor: pointer;
}
.cart-contianer section {
  display: grid;
  place-items: center;
  height: calc(100% - 3rem);
  width: 100%;
}
.cart-contianer section.empty div {
  display: grid;
  place-items: center;
  color: var(--greyColor);
  transform: scale(1);
  animation: appear 1s;
}
@keyframes appear {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.cart-contianer section.empty img {
  width: 80%;
}
.cart-contianer section.cart-items {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}
.cart-contianer section.cart-items::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  opacity: 0;
}
.shop-section {
  position: absolute;
  bottom: 2.5%;
  left: 50%;
  transform: translate(-50%);
  height: 2rem;
  width: fit-content;
  padding: 0 0.75rem;
  display: grid;
  place-content: center;
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: var(--darkGreyColor);
  color: var(--greyColor);
  border: 2px solid var(--greyColor);
  cursor: pointer;
}
@media screen and (max-width: 380px) {
  .cart-contianer {
    width: 80vw;
    right: 5vw;
  }
}
