.landing-container {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url("../assists/home-background.png");
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}
.landing-shoes-details {
  position: absolute;
  top: 70%;
  left: 58%;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  font-family: "Goldman";
}
.landing-cost {
  color: var(--redColor);
  font-size: 2.25rem;
  font-weight: bolder;
}
.landing-brand--model {
  letter-spacing: 0.4rem;
  color: var(--whiteColor);
}
.description--otherColors {
  position: absolute;
  bottom: 1.25%;
  left: 5%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Goldman";
}
.other-colors-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  color: var(--whiteColor);
}
.other-colors-container h4,
.description-container h4 {
  margin: 0;
}
.description-container {
  width: 30rem;
  color: var(--whiteColor);
}
.description-container h4 {
  font-size: 1.25rem;
  font-weight: 1000;
}
.description-container p {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-family: Roboto;
  line-height: 1.3rem;
}
@media screen and (max-width: 1100px) {
  .landing-container {
    background-image: url("../assists/home-background-mobile.png");
    background-position: center;
  }
  .landing-cost {
    font-size: 1.5rem;
    color: var(--greyColor);
  }
  .landing-shoes-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: -3.5rem;
    font-size: 0.6rem;
    width: 50vw;
  }
  .description-container {
    display: none;
  }
  .description--otherColors {
    bottom: 1%;
    font-size: 0.8rem;
  }
  .otherColorImage {
    width: 60vw;
  }
}
