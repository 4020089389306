$redColor: #e70000;
$darkRedColor: #eb0000;
$whiteColor: #ffffff;
$lightGreyColor: #999999;
$greyColor: #494949;
$darkGreyColor: #202020;
$backgroundColor: #090909;
$backgroundGreyColor: rgba(50, 50, 50, 0.58);
$lightBlack: rgba(0, 0, 0, 0.6);
$greenColor: #00ffd5;
$boxShadow: 0 0 10px $lightBlack;
$boxShadowLight: 0 0 5px $backgroundColor;
