@import "./variables/variables.scss";
.cart-item-container {
  margin-top: 1.25rem;
  height: 8rem;
  width: 97%;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  background-color: $darkGreyColor;
  box-shadow: $boxShadow;
  a {
    all: unset;
  }
  a.shoes-preview {
    height: 80%;
    width: 35%;
    margin: 0 0.5rem;
    img {
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
  }
  div {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    font-size: 1rem;
    color: $whiteColor;
  }
  div div:nth-child(2) {
    height: 1.5rem;
    width: 11rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .cart-stars-contianer {
    position: relative;
    width: 45%;
    height: 0.9rem;
    img.cart-stars {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .cart-stars-upper {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: start;
      column-gap: 0.09rem;
      img.cart-star {
        height: 0.9rem;
        height: 0.9rem;
      }
    }
  }
  div div h6 {
    max-width: 40%;
    font-size: 0.7rem;
    text-align: center;
    color: $greyColor;
    overflow: hidden;
  }
  div h5 {
    all: unset;
    font-size: 1.7rem;
    max-width: 5.5rem;
    overflow: hidden;
  }
  div.remove--add {
    all: unset;
    position: absolute;
    left: 56%;
    top: 72.5%;
    width: 5rem;
    height: 1.85rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: $greyColor;
    box-shadow: $boxShadowLight;
    border-radius: 0.5rem;
    transition: all 1s;
  }
  .remove--add button {
    all: unset;
    display: grid;
    place-content: center;
    cursor: pointer;
    height: 100%;
  }
  .remove--add button:first-child {
    :hover {
      color: $darkRedColor;
    }
  }
  .remove--add button:nth-child(2) {
    :hover {
      color: $greenColor;
    }
  }
  .remove--add button:nth-child(3) {
    :hover {
      color: $whiteColor;
    }
  }
}

@media screen and (max-width: 380px) {
  .cart-item-container {
    padding: 2.5vw 0;
    width: 98%;
    height: fit-content;
    img.shoes-preview {
      width: 25vw;
      height: 25vw;
    }
    div {
      font-size: 3.5vw;
    }
    div div:nth-child(2) {
      height: 4vw;
      width: 45.5vw;
      .cart-stars-contianer {
        height: 4vw;
        .cart-stars-upper {
          height: 100%;
          width: 100%;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: start;
          column-gap: 0vw;
          img.cart-star {
            height: 4vw;
            height: 4vw;
          }
        }
      }
    }
    div div h6 {
      font-size: 3vw;
      max-width: 14vw;
    }
    div h5 {
      font-size: 6vw;
      max-width: 22vw;
      overflow-x: auto;
    }
    div h5::-webkit-scrollbar {
      height: 3px;
    }
    div.remove--add {
      all: unset;
      position: absolute;
      left: 49%;
      top: 69%;
      width: 52.5%;
      height: 32.5%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      color: $greyColor;
      box-shadow: $boxShadowLight;
      border-radius: 0.5rem;
      transition: all 1s;
    }
  }
}
