.cart-enter {
  transform: translateX(150%);
  transition: all 300ms;
}
.cart-enter-active {
  transform: translateX(0%);
  transition: all 300ms;
}
.cart-exit {
  transform: translateX(0%);
  transition: all 600ms;
}
.cart-exit-active {
  transition: all 600ms;
  transform: translateX(150%);
}
.close-section {
  position: fixed;
  top: 0%;
  z-index: 1;
  height: 100%;
  width: 100%;
}
