.products-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  padding-bottom: 2rem;
}
@media screen and (max-width: 960px) {
  .products-container {
    flex-direction: column;
    align-items: center;
  }
}
