.contact-page {
  padding-left: 5%;
  background-image: url("../assists/product-background.png");
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-section {
  height: 68%;
  width: 28rem;
  border-radius: 1rem;
  border: 2px solid #202020;
  background-color: #090909;
}
.email-section h2 {
  margin: 1rem 0 0 1rem;
  font-size: 1.75rem;
  color: #e70000;
}
.email-section p {
  padding: 0.5rem 1rem 1rem 1rem;
  font-size: 0.8rem;
  color: #494949;
  margin: 0;
  text-align: justify;
}

.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-section input,
.form-section textarea {
  all: unset;
  box-sizing: border-box;
  width: 95%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  text-align: start;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  color: #999999;
  border: 3px solid #202020;
}
.form-section input::-moz-placeholder, .form-section textarea::-moz-placeholder {
  color: #202020;
}
.form-section input:-ms-input-placeholder, .form-section textarea:-ms-input-placeholder {
  color: #202020;
}
.form-section input::placeholder,
.form-section textarea::placeholder {
  color: #202020;
}
.form-section textarea {
  width: 95%;
  height: 10rem;
  font-size: 1rem;
}
.form-section button {
  all: unset;
  width: calc(95% - 6px);
  height: 2.75rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: #202020;
  border-radius: 0.5rem;
  border: 3px solid #202020;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-section button.valid {
  color: #eb0000;
  border: 3px solid #eb0000;
}

.landing-section {
  width: 34.5rem;
  height: 34.5rem;
  box-sizing: border-box;
  background-image: url("../assists/contact-landing-background.png");
  background-position: right;
  background-size: cover;
}

@media screen and (max-width: 1000px) {
  .contact-page {
    padding: 10rem 0 2rem 0;
    background-position: center;
    background-size: cover;
    height: 100%;
    background-color: aqua;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .email-section {
    width: 90vw;
    height: 99vw;
    border-radius: 3vw;
  }
  .email-section h2 {
    margin: 2vw 0 0 2vw;
    font-size: 4.5vw;
  }
  .email-section p {
    padding: 1vw 4vw 2vw 4vw;
    font-size: 2.25vw;
  }

  .form-section input,
.form-section textarea {
    all: unset;
    box-sizing: border-box;
    width: 95%;
    padding: 1.5vw;
    margin-bottom: 2vw;
    text-align: start;
    font-size: 2.75vw;
    border-radius: 0.5rem;
    color: #999999;
    border: 3px solid #202020;
  }
  .form-section textarea {
    height: 40vw;
    font-size: 2.5vw;
  }
  .form-section button {
    all: unset;
    width: calc(95% - 6px);
    height: 7vw;
    margin-bottom: 0;
    font-size: 2.75vw;
    color: #202020;
    border-radius: 0.5rem;
    border: 3px solid #202020;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-section button.valid {
    color: #eb0000;
    border: 3px solid #eb0000;
  }

  .landing-section {
    width: 100vw;
    height: 100vw;
    margin-right: 0%;
    box-sizing: border-box;
    background-image: url("../assists/contact-landing-background.png");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
  }
}/*# sourceMappingURL=contact.css.map */