.product-page-main-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url("../assists/product-background.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  color: #494949;
  display: grid;
  place-items: center;
}

.product-page-container {
  width: 75rem;
  height: 37rem;
  margin-top: 4rem;
  border: 2px solid #202020;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.shoes-pictures-container {
  width: 35%;
  height: 100%;
  display: flex;
  row-gap: 2rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.shoes-pictures-container .main-picture {
  width: 23rem;
  height: 21rem;
}
.shoes-pictures-container div {
  display: flex;
  align-items: center;
}
.shoes-pictures-container div img {
  width: 6rem;
  height: 6rem;
  cursor: pointer;
}

.shoes-information {
  all: unset;
  width: 35%;
  height: 90%;
  padding-right: 0.5rem;
}
.shoes-information h1 {
  margin: 0;
  font-size: 1.5rem;
  color: #ffffff;
}
.shoes-information h1:first-child {
  margin-top: 1rem;
}

.select-colors,
.select-size {
  margin-top: 1rem;
  font-size: 1.25rem;
  color: #494949;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: space-between;
}
.select-colors span,
.select-size span {
  margin-left: 0.25rem;
  font-weight: lighter;
  font-size: 0.85rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.select-colors span :hover,
.select-size span :hover {
  color: #999999;
}
.select-colors .selected,
.select-size .selected {
  color: #e70000;
}

.select-size {
  margin-top: 0.75rem;
  font-weight: none;
  font-size: 1.25rem;
  height: 2rem;
}
.select-size select {
  padding: 0.125rem 0;
  transform: translateY(-10%);
  font-size: 1rem;
  font-family: "Goldman";
  color: #494949;
  background-color: transparent;
  border: none;
  border-bottom: 0.2rem solid #e70000;
  outline: none;
  cursor: pointer;
}
.select-size select option {
  background-color: #494949;
  color: #999999;
}

.details h2 {
  font-size: 1.5rem;
  margin: 1rem 0;
}
.details div {
  margin-left: 1rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.details div h4,
.details div p {
  font-size: 1rem;
}

.product-stars--price {
  margin-top: 1rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-stars--price div:first-child {
  position: relative;
  height: 1.25rem;
}
.product-stars--price div:first-child img:first-child {
  height: 100%;
}
.product-stars--price div:first-child div {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  -moz-column-gap: 0.22rem;
       column-gap: 0.22rem;
}
.product-stars--price div:first-child div img {
  height: 1.25rem;
  width: 1.25rem;
}
.product-stars--price h5 {
  margin: 0;
  font-size: 1.75rem;
  color: #e70000;
}

.free-delivery {
  margin-top: 1rem;
}
.free-delivery h5 {
  margin: 0;
  font-size: 1.25rem;
}
.free-delivery h5:last-child {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.poduct-page-button-container {
  margin-top: 1rem;
  height: 3.5rem;
  width: 100%;
  overflow: hidden;
}

.product-page-button {
  margin-top: 0rem;
  height: 100%;
}

.comments {
  width: 28%;
  height: 85%;
  margin: 0 2% 0 2%;
  padding-left: 2%;
  position: relative;
}
.comments div.comments-container {
  box-sizing: border-box;
  height: calc(100% - 8rem);
  width: 100%;
  overflow-y: auto;
  padding-right: 0.5rem;
  scroll-behavior: smooth;
}
.comments div.comments-container::-webkit-scrollbar {
  width: 0.25rem;
}
.comments div.comments-container::-webkit-scrollbar-thumb {
  background-color: #202020;
}

.comments::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0;
  width: 0.2rem;
  height: 100%;
  border-radius: 1rem;
  background-color: var(--darkGreyColor);
}

.comment {
  position: relative;
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.comment img {
  height: 2.5rem;
  width: 2.5rem;
}
.comment div.comment-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 0.5rem;
}
.comment div.comment-data h3 {
  margin: 0;
  color: #ffffff;
  font-size: 1rem;
}
.comment div.comment-data h4 {
  margin: 0.25rem 0 0 0.5rem;
  padding-right: 0.5rem;
  color: #494949;
  font-size: 0.55rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comment div.comment-data P {
  margin: 0.25rem 0 0 0.75rem;
  color: #999999;
  font-size: 0.75rem;
  width: 15rem;
  overflow: hidden;
  text-align: justify;
}
.comment .product-star-rate {
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
  height: 0.75rem;
  display: flex;
}
.comment .product-star-rate img.product-star-rate-img {
  height: 100%;
  width: 100%;
}
.comment .product-star-rate div {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  -moz-column-gap: 0.13rem;
       column-gap: 0.13rem;
}
.comment .product-star-rate div img {
  width: 0.75rem;
  height: 0.75rem;
}

.comments-form {
  position: absolute;
  top: calc(100% - 7.5rem);
  box-sizing: border-box;
  height: 7.5rem;
  border-radius: 0.5rem;
  width: 90%;
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  padding-top: 0.5rem;
}

.comments-form::after {
  content: "";
  position: absolute;
  left: -2.5%;
  top: 0;
  width: 105%;
  height: 0.2rem;
  border-radius: 1rem;
  background-color: #202020;
}

.comment-form-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.5rem;
  width: 100%;
  margin-top: 0.25rem;
}
.comment-form-section h6 {
  font-size: 0.8rem;
}
.comment-form-section .comment-stars-container {
  position: relative;
  width: 5.5rem;
  height: 1rem;
  display: flex;
  align-items: center;
}
.comment-form-section .comment-stars-container img:first-child {
  position: absolute;
  height: 100%;
}
.comment-form-section .comment-stars-container .comment-stars-star {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  -moz-column-gap: 0.19rem;
       column-gap: 0.19rem;
}
.comment-form-section .comment-stars-container .comment-stars-star img {
  width: 1rem;
  height: 1rem;
}
.comment-form-section .comment-stars-container .comment-stars-star-button {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  -moz-column-gap: 0.19rem;
       column-gap: 0.19rem;
  opacity: 0;
}
.comment-form-section .comment-stars-container .comment-stars-star-button img {
  width: 1rem;
  height: 1rem;
  opacity: 0;
  cursor: pointer;
}

.comment-form-section .yes-no {
  height: 100%;
  width: 4rem;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comment-form-section .yes-no h6 {
  font-size: 0.9rem;
  cursor: pointer;
}
.comment-form-section .yes-no h6.suggested {
  color: #e70000;
}

.comment-input {
  position: relative;
  height: 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comment-input input,
.comment-input button {
  border: none;
  outline: none;
  background-color: transparent;
}
.comment-input input {
  font-size: 0.9rem;
  font-family: "goldman";
  height: 100%;
  color: #999999;
  width: calc(100% - 2rem);
}
.comment-input input::-moz-placeholder {
  color: #494949;
}
.comment-input input:-ms-input-placeholder {
  color: #494949;
}
.comment-input input::placeholder {
  color: #494949;
}
.comment-input button {
  background-color: transparent;
  height: 3rem;
  width: 2rem;
  color: #e70000;
  cursor: pointer;
}

.comment-input::after {
  content: "";
  position: absolute;
  left: -2.5%;
  top: 110%;
  width: 105%;
  height: 0.2rem;
  border-radius: 1rem;
  background-color: #202020;
}

@media screen and (max-width: 1250px) {
  .product-page-main-container {
    background-repeat: repeat-y;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5rem 0;
  }

  .product-page-container {
    flex-direction: column;
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .shoes-pictures-container {
    width: 90%;
  }
  .shoes-pictures-container .main-picture {
    height: 50vw;
    width: 58vw;
  }
  .shoes-pictures-container div img {
    height: 20vw;
    width: 22vw;
  }

  .shoes-information {
    width: 90%;
  }
  .shoes-information h1 {
    font-size: 5vw;
  }
  .shoes-information h1:first-child {
    margin-top: 2.5rem;
  }

  .select-colors,
.select-size {
    font-size: 3.5vw;
  }

  .details h2 {
    font-size: 5vw;
  }
  .details div {
    margin-left: 4vw;
    height: 7vw;
  }
  .details div h4,
.details div p {
    font-size: 3vw;
  }

  .product-stars--price {
    height: 6vw;
  }
  .product-stars--price div:first-child {
    height: 5vw;
  }
  .product-stars--price div:first-child div {
    -moz-column-gap: 0.9vw;
         column-gap: 0.9vw;
  }
  .product-stars--price div:first-child div img {
    height: 5vw;
    width: 5vw;
  }
  .product-stars--price h5 {
    font-size: 6vw;
  }

  .free-delivery h5 {
    font-size: 4vw;
  }
  .free-delivery h5:last-child {
    font-size: 3.5vw;
  }

  .poduct-page-button-container {
    margin-top: 1rem;
    height: 11vw;
  }

  .comments {
    width: 90%;
    height: 100%;
    margin-top: 2rem;
    padding: 0;
  }
  .comments .comments-container {
    height: 50%;
    overflow: hidden;
  }

  .comments::after {
    display: none;
  }

  .comment img {
    height: 9vw;
    width: 9vw;
  }
  .comment div.comment-data {
    margin-left: 2vw;
  }
  .comment div.comment-data h3 {
    font-size: 4vw;
  }
  .comment div.comment-data h4 {
    margin: 1vw 0 0 2vw;
    font-size: 2.25vw;
  }
  .comment div.comment-data P {
    margin: 0.25rem 0 0 0.75rem;
    font-size: 3vw;
  }
  .comment .product-star-rate {
    position: absolute;
    top: 1vw;
    right: 2vw;
    height: 3vw;
  }
  .comment .product-star-rate div {
    -moz-column-gap: 0.55vw;
         column-gap: 0.55vw;
  }
  .comment .product-star-rate div img {
    width: 3vw;
    height: 3vw;
  }

  .comments-form {
    position: static;
    margin-top: 1rem;
    height: 35vw;
    width: 100%;
    padding-top: 2vw;
  }

  .comment-form-section {
    height: 7vw;
    margin-top: 1vw;
  }
  .comment-form-section h6 {
    font-size: 3vw;
  }
  .comment-form-section .comment-stars-container {
    width: 28vw;
    height: 5vw;
  }
  .comment-form-section .comment-stars-container .comment-stars-star {
    -moz-column-gap: 0.9vw;
         column-gap: 0.9vw;
  }
  .comment-form-section .comment-stars-container .comment-stars-star img {
    width: 5vw;
    height: 5vw;
  }
  .comment-form-section .comment-stars-container .comment-stars-star-button {
    -moz-column-gap: 0.9vw;
         column-gap: 0.9vw;
    opacity: 0;
  }
  .comment-form-section .comment-stars-container .comment-stars-star-button img {
    width: 5vw;
    height: 5vw;
    opacity: 0;
    cursor: pointer;
  }

  .comment-form-section .yes-no {
    width: 18vw;
  }
  .comment-form-section .yes-no h6 {
    font-size: 3.5vw;
  }

  .comment-input {
    position: relative;
    height: 8vw;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .comment-input input,
.comment-input button {
    border: none;
    outline: none;
    background-color: transparent;
  }
  .comment-input input {
    font-size: 3.25vw;
    font-family: "goldman";
    height: 100%;
    color: #999999;
    width: calc(100% - 2rem);
  }
  .comment-input button {
    height: 100%;
    width: 5vw;
  }

  .comment-input::after {
    background-color: #e70000;
  }
}/*# sourceMappingURL=productPage.css.map */